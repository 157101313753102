import { css } from '@emotion/react';
import {
  fontSize,
  lineHeight,
  spacing,
} from '@prototyp/gatsby-plugin-gumball/mixins';
import { flex } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  ${flex.display.flex};
  ${flex.wrap.wrap};
  ${flex.align.center};
  border-bottom: 1px solid hsl(var(--color-borderLight-8));
  margin-bottom: ${spacing(32)};
  position: relative;

  input::placeholder {
    color: hsl(var(--color-grayscale-4));
  }

  &:hover {
    border-color: hsl(var(--color-borderDark-5));
  }
`;

export const rootError = css`
  ${root};
  border-color: hsl(var(--color-error-7));
`;

export const input = css`
  display: block;
  flex: 1;
  outline: 0;
  border-width: 0;
  background: transparent;
  padding: ${spacing(8)} 0;
  font-size: ${fontSize('base')};
  line-height: ${lineHeight('base')};
`;

export const inputWithIcon = css`
  ${input};
  padding-right: ${spacing(48)};
`;

export const inputTitle = css`
  ${input};
  font-family: var(--font-family-secondary);
  font-weight: normal;
  font-size: ${fontSize('xxxLarge')};
  line-height: ${lineHeight('xxxLarge')};
  padding-left: ${spacing(4)};
  border-left: 1px solid hsl(var(--color-textLight-7));
  max-width: 100%;

  &:hover,
  &:focus {
    border-left: 1px solid hsl(var(--color-textLight-5));
  }

  &::placeholder {
    color: hsl(var(--color-textLight-7));
  }
`;

export const inputTitleError = css`
  ${inputTitle};
  border-left: 2px solid hsl(var(--color-error-7));

  &:hover,
  &:focus {
    border-left: 1px solid hsl(var(--color-error-7));
  }
`;

export const icon = css`
  flex-basis: ${spacing(16)};
  width: ${spacing(16)};
`;

export const iconPassword = css`
  flex-basis: ${spacing(16)};
  width: ${spacing(16)};

  &:hover {
    cursor: pointer;
  }
`;

export const helpContainer = css`
  position: absolute;
  padding-top: ${spacing(4)};
  top: 100%;
  left: 0;
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};
  color: hsl(var(--color-text-1));
`;

export const fieldError = css`
  color: hsl(var(--color-error-7));
  font-size: ${fontSize('small')};
  line-height: var(--line-height-small);
`;

export const optional = css`
  font-size: ${fontSize('tiny')};
  line-height: ${lineHeight('tiny')};
  color: hsl(var(--color-grayscale-4));
`;

export const labelWrapper = css`
  ${flex.display.flex};
  ${flex.wrap.wrap};
  ${flex.align.center};
  ${flex.justify.between};

  flex-basis: 100%;
  flex-grow: 1;
  font-size: ${fontSize('small')};
  line-height: ${lineHeight('small')};
  color: hsl(var(--color-grayscale-6));
`;

export const labelWrapperError = css`
  ${labelWrapper};

  color: hsl(var(--color-error-7));
`;

/** Maybe should be moved to utils */
export const inputFullWidth = css`
  width: 100%;
`;
